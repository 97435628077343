import { mapActions, mapGetters } from 'vuex';
import CommentAdd from '@/components/custom/CommentsBlock/CommentAdd/index.vue';
import { COMMENT_TYPE, countComments } from '@/helpers';
import { pluralizate } from '@/helpers/variables';
import { cloneDeep } from 'lodash';
import CommentCard from '@/components/custom/CommentsBlock/CommentCard/index.vue';

export default {
  name: 'CommentsBlock',
  components: {
    CommentCard,
    CommentAdd
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    id: {
      type: [Number, String, null],
      default: null
    },
    type: {
      type: [String, null],
      required: true
    },
    isCanAddGeneral: {
      type: Boolean,
      default: true
    },
    isCanAddUser: {
      type: Boolean,
      default: true
    }
  },
  emits: ['comment-counter'],
  data() {
    return {
      COMMENT_TYPE,
      commentsCount: 0,
      comments: []
    };
  },
  created() {
    console.log('data', this.data);
    this.commentsCount = countComments(this.data, this.type);
    this.comments = cloneDeep(this.data);
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters({}),
    commentsPluralizate() {
      return pluralizate(
        this.commentsCount ? this.commentsCount : 0,
        this.$t('label.comments.countOne'),
        this.$t('label.comments.countSimple'),
        this.$t('label.comments.countMany')
      );
    }
  },

  methods: {
    ...mapActions({}),
    onUpdateData(data) {
      if (data[this.type] && data[this.type].data.length) {
        this.commentsCount = countComments(data[this.type].data, this.type);
        this.comments = cloneDeep(data[this.type].data);
        this.$emit('comment-counter', true);
      }
    },
    onUpdateCounter() {
      this.commentsCount++;
      this.$emit('comment-counter', true);
    }
  }
};
