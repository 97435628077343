import StarRating from 'vue-star-rating';
import { mapActions, mapGetters } from 'vuex';
import { email, required } from 'vuelidate/lib/validators';
import validation from '@/mixins/validation';
import { COMMENT_TYPE } from '@/helpers';

export default {
  name: 'CommentAdd',
  components: {
    StarRating
  },
  props: {
    id: {
      type: [Number, String, null],
      default: null
    },
    parentId: {
      type: [Number, String, null],
      default: null
    },
    isSubComment: {
      type: Boolean,
      default: false
    },
    type: {
      type: [String, null],
      default: null,
      required: true
    }
  },
  emits: ['comment-added'],
  data() {
    return {
      COMMENT_TYPE,
      payload: {
        name: '',
        email: '',
        comment: ''
      },
      isLoadingCommentAdd: false,
      validationErrors: {}
    };
  },
  mixins: [validation],
  validations: {
    payload: {
      email: {
        email,
        required
      },
      comment: {
        required
      }
    }
  },
  created() {
    if (!this.isSubComment) {
      this.payload = { ...this.payload, rating: 0 };
    }
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters({}),
    emailErrors() {
      return this.computeErrors('payload', 'email', {
        email: 'validation.email',
        required: 'validationRequired'
      });
    },
    messageErrors() {
      return this.computeErrors('payload', 'comment', {
        required: 'validationRequired'
      });
    }
  },

  methods: {
    ...mapActions({
      addBlogComment: 'comments/ADD_BLOG_COMMENT',
      addBlogUserComment: 'comments/ADD_BLOG_USER_COMMENT',
      addProductComment: 'comments/ADD_PRODUCT_COMMENT'
    }),
    _resetForm() {
      Object.keys(this.payload).forEach((key) => (this.payload[key] = key === 'rating' ? 0 : ''));
      this.$v.$reset();
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let promiseCollection = [];
        this.isLoadingCommentAdd = true;
        if (this.type === COMMENT_TYPE.COMMENTS) {
          if (this.isSubComment) {
            promiseCollection = [
              this.addBlogUserComment({ ...this.payload, comment_id: this.parentId }).then((result) => {
                this.$emit('comment-added', result.data);
              })
            ];
          } else {
            promiseCollection = [
              this.addBlogComment({ ...this.payload, news_id: this.id }).then((result) => {
                this.$emit('comment-added', result.data);
              })
            ];
          }
        } else if (this.type === COMMENT_TYPE.FEEDBACKS) {
          let { comment, ...otherParams } = this.payload;
          let payload = { ...otherParams, product_id: this.id, message: comment };
          payload = this.isSubComment ? { ...payload, parent_id: this.parentId } : payload;
          promiseCollection = [this.addProductComment(payload)];
        }

        Promise.all(promiseCollection)
          .then(() => {
            this.$toasted.success(this.$t('successFeedbackSend'));
            this._resetForm();
          })
          .finally(() => {
            this.isLoadingCommentAdd = false;
          });
      }
    }
  }
};
