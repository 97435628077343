import { mapActions, mapGetters } from 'vuex';
import CommentAdd from '@/components/custom/CommentsBlock/CommentAdd/index.vue';
import { COMMENT_TYPE } from '@/helpers';
import StarRating from 'vue-star-rating';
import IconMessage from '@/components/icons/IconMessage.vue';
import { cloneDeep } from 'lodash';

export default {
  name: 'CommentCard',
  components: {
    IconMessage,
    StarRating,
    CommentAdd
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    id: {
      type: [Number, String, null],
      default: null
    },
    type: {
      type: [String, null],
      default: null,
      required: true
    },
    isSubComment: {
      type: Boolean,
      default: false
    },
    isCanAddUser: {
      type: Boolean,
      default: true
    }
  },
  emits: ['comment-counter'],
  data() {
    return {
      COMMENT_TYPE,
      isShowForm: false,
      isShowMore: false,
      isShowAnswers: false,
      isOverflowing: false,
      comments: []
    };
  },
  created() {
    let child = this.type === COMMENT_TYPE.COMMENTS ? this.data.comments : this.data.child;
    this.comments = child.data && child.data.length ? cloneDeep(child.data) : [];
  },
  mounted() {
    this._checkOverflow();
  },
  watch: {},
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated'
    }),
    commentText() {
      return this.type === COMMENT_TYPE.COMMENTS ? this.data.comment : this.data.message;
    }
  },

  methods: {
    ...mapActions({}),
    onToggleForm() {
      this.isShowForm = !this.isShowForm;
    },
    onToggleShowMore() {
      this.isShowMore = !this.isShowMore;
    },
    onToggleAnswers() {
      this.isShowAnswers = !this.isShowAnswers;
    },
    _checkOverflow() {
      const container = this.$refs.refMessage;
      this.isOverflowing = container.scrollHeight > container.clientHeight;
    },
    onUpdateData(data) {
      this.comments = data.comments.data;
      this.isShowForm = false;
      this.$emit('comment-counter', true);
    },
    onUpdateCounter() {
      this.$emit('comment-counter', true);
    }
  }
};
